/*
  ProductPage.js
  section 1: Hero section
  section 2: Progress Section
  section 3: Step Section
  section 4: SignUp Section
*/
  // Module:
import React, { useState, useEffect, useContext } from "react";
import { SectionIndicatorContext, BasicContext } from '../../../context/MainContext';
  // Style:
import '../../../styles/letsPlay.scss';
  // components
import HeroSection from './HeroSection';
import ProgressSection from './ProgressSection';
import StepSection from './StepSection';
import SignUpSection from './SignUpSection';
  // static
const section1Height = 600;
const section2Height = 12000;
const section3Height = 4500;
// const section4Height = 6400;
  
// const Product = ({headerHeight, windowHeight, windowWidth, scrollPosition}) => {
const Product = () => {
    const { headerHeight, windowHeight, windowWidth, scrollPosition } = useContext(BasicContext);
    const [activeSection, setActiveSection] = useState('section1');
    const [pastSection, setPastSection] = useState('section0');
    useEffect(() => {
        document.documentElement.style.overflow = 'visible';
    }, []);
    
    useEffect(() => {
        // 当前用户浏览区域 active section
        if(scrollPosition >= headerHeight+section1Height+section2Height){
            setActiveSection('section3');
        }else if(scrollPosition >= headerHeight+section1Height){
            setActiveSection('section2');
        }else{
            setActiveSection('section1');
        }
        // 判断用户是否离开当前区域 if move to a new section
        if(pastSection!==activeSection){
            setPastSection(activeSection)
        }
    }, [scrollPosition, activeSection, pastSection])

    return(
        <SectionIndicatorContext.Provider value={{ activeSection, pastSection, scrollPosition, windowHeight }}>
            <div className="productpage">
                <section className="heroSection" style={{ height: `${section1Height}px`}}>
                    <HeroSection />
                </section>
                <section className="progressSection" style={{ height: `${section2Height}px` }}>
                    <ProgressSection headerHeight={headerHeight} section1Height={section1Height} section2Height={section2Height} />
                </section>
                <section className="stepSection" style={{ height: `${section3Height}px`}}>
                    <StepSection headerHeight={headerHeight} section1Height={section1Height} section2Height={section2Height} section3Height={section3Height}/>
                </section>
                <section className="signUpSection" >
                    <SignUpSection />
                </section>
            </div>
        </SectionIndicatorContext.Provider>
    );
};

export default Product;