import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import style from './CustomCursor.module.scss'; // Import the CSS module

const CustomCursor = () => {
    const circleRef = useRef(null); // Create a ref for the circle element
    const mouse = useRef({ x: 0, y: 0 });
    const previousMouse = useRef({ x: 0, y: 0 });
    const circle = useRef({ x: 0, y: 0 });
    const currentScale = useRef(0);
    const currentAngle = useRef(0);
    const circleSize = useRef(30); // Default size for the cursor
    const location = useLocation(); // Get the current route location

    useEffect(() => {
        const handleMouseMove = (e) => {
            mouse.current.x = e.clientX;
            mouse.current.y = e.clientY;
        };

        const handleMouseEnterLink = () => {
            circleSize.current = 60; // Increase the size of the cursor
            circleRef.current.style.width = `${circleSize.current}px`;
            circleRef.current.style.height = `${circleSize.current}px`;
        };

        const handleMouseLeaveLink = () => {
            circleSize.current = 30; // Reset the size of the cursor
            circleRef.current.style.width = `${circleSize.current}px`;
            circleRef.current.style.height = `${circleSize.current}px`;
        };

        const attachLinkListeners = () => {
            document.querySelectorAll('a, .link').forEach((el) => {
                el.addEventListener('mouseenter', handleMouseEnterLink);
                el.addEventListener('mouseleave', handleMouseLeaveLink);
            });
        };

        const detachLinkListeners = () => {
            document.querySelectorAll('a, .link').forEach((el) => {
                el.removeEventListener('mouseenter', handleMouseEnterLink);
                el.removeEventListener('mouseleave', handleMouseLeaveLink);
            });
        };

        const tick = () => {
            const speed = 0.17;

            // MOVE
            circle.current.x += (mouse.current.x - circle.current.x) * speed;
            circle.current.y += (mouse.current.y - circle.current.y) * speed;
            const translateTransform = `translate(${circle.current.x - circleSize.current / 2}px, ${circle.current.y - circleSize.current / 2}px)`;

            // SQUEEZE
            const deltaMouseX = mouse.current.x - previousMouse.current.x;
            const deltaMouseY = mouse.current.y - previousMouse.current.y;
            previousMouse.current.x = mouse.current.x;
            previousMouse.current.y = mouse.current.y;

            const mouseVelocity = Math.min(Math.sqrt(deltaMouseX ** 2 + deltaMouseY ** 2) * 4, 150);
            const scaleValue = (mouseVelocity / 150) * 0.5;
            currentScale.current += (scaleValue - currentScale.current) * speed;
            const scaleTransform = `scale(${1 + currentScale.current}, ${1 - currentScale.current})`;

            // ROTATE
            const angle = Math.atan2(deltaMouseY, deltaMouseX) * 180 / Math.PI;
            if (mouseVelocity > 20) {
                currentAngle.current = angle;
            }
            const rotateTransform = `rotate(${currentAngle.current}deg)`;

            // Apply transformations
            if (circleRef.current) {
                circleRef.current.style.transform = `${translateTransform} ${rotateTransform} ${scaleTransform}`;
            }

            window.requestAnimationFrame(tick);
        };

        // Attach listeners on mount and whenever the route changes
        window.addEventListener('mousemove', handleMouseMove);
        attachLinkListeners();
        window.requestAnimationFrame(tick); // Start the animation loop

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            detachLinkListeners();
        };
    }, [location.pathname]); // Dependency on location.pathname to re-attach listeners on route change

    return (
        <div ref={circleRef} className={style.circle}>
        </div>)
        ;
};

export default CustomCursor;