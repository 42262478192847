import React, {useEffect} from 'react';
// import ArtDisplay from '../../components/sections/ArtDisplay';
// import WorkDisplay from '../../components/sections/WorkDisplay';
import { MoreButton, PlayButton } from '../../components/buttons/Button_Common';
import Gallery from '../../components/sections/Gallery';
import '../../styles/design.scss';
// import { RightOutlined, PlayCircleOutlined, PlayCircleFilled } from '@ant-design/icons';

const ArtPage = () => {
    // useEffect(() => {
    //     window.location.reload();
    // }, []);
    const frontend = [
        {
            // image: "https://i.ibb.co/YbgDYnJ/lady.png",
            image: "/assets/portfolio/uiux/lady.png",
            title: 'LetsPlay',
            problem: 'I developed a ready-to-launch iOS game app designed to entertain a group of people using a single device.',
            targetUser: 'Physical games are inconvenient to carry.',
            myRole: 'UI/UX Designer & IOS Developer',
            herf: 'https://drive.google.com/file/d/1KHW4tSWBHc150EovTYGB1iVDG9dXVEa4/view?usp=drive_link',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1KHW4tSWBHc150EovTYGB1iVDG9dXVEa4/view?usp=drive_link" useLink={true} />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1Nzg=" />
                    ), isMain: true
                },
                // {
                //     button: (
                //         <PlayButton link={"https://drive.google.com/file/d/1KHW4tSWBHc150EovTYGB1iVDG9dXVEa4/view?usp=sharing"} />
                //     ), isMain: false
                // },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: "/assets/portfolio/uiux/vision.png",
            title: 'BMW x SCAD',
            problem: 'It is a collaboration project. We designed and developed a VisionPro app for BMW.',
            targetUser: 'Physical games are inconvenient to carry.',
            myRole: 'Mixed-reality Developer',
            herf: 'https://drive.google.com/file/d/1KHW4tSWBHc150EovTYGB1iVDG9dXVEa4/view?usp=drive_link',
            buttons: [
                {
                    button: (
                        <PlayButton link={"https://www.bilibili.com/video/BV12cH8egErL/"} />
                    ), isMain: false
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/9wSbjLF/imlearn.png",
            image: "/assets/portfolio/uiux/imlearn.png",
            title: 'Imlearn',
            problem: 'Current educational apps are fragmented and lack integration, forcing users to download multiple apps to access different immersive educational content.',
            targetUser: 'An edutainment platform in VisionPro to help users experience rich and immersive edutainment content.',
            myRole: 'Researcher & Frontend Prototyper',
            link: "https://drive.google.com/file/d/1_nyv5d0WvvieIChXXou37v1AZoP97QKV/view",
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1_nyv5d0WvvieIChXXou37v1AZoP97QKV/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1Nzk=" />
                    ), isMain: true
                },
                {
                    button: (
                        // <PlayButton link="https://drive.google.com/file/d/14CH4xrCqBoS8cPJLlIDMcNhCBbF_4ZQR/view?usp=sharing" />
                        <PlayButton link="https://www.bilibili.com/video/BV1KzHzeKEsi/" />
                    ), isMain: false
                },
            ],
            backgroundColor: '#e2e2e2',
        },
    ];
    
    const plugin = [
        {
            image: "/assets/portfolio/uiux/plugin.png",
            title: 'Oink GUI Animation Framework',
            problem: 'Oink Animation streamlines 2D GUI animations in Unity, offering a fast, low-code, and customizable workflow for developers at any level to create dynamic and engaging user interfaces.',
            software: 'VSCode',
            language: 'C#',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1XLN5zvA16i3xuADsTfvCcnWE855C4kjW/view?usp=drive_link',
            buttons: [
                {
                    button: (
                        <MoreButton link="https://assetstore.unity.com/packages/2d/gui/2d-gui-animation-framework-293489#description" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: "/assets/portfolio/uiux/cover-figma-thumb.png",
            title: 'Oink StyleUp Figma Plugin',
            problem: 'Effortlessly style layouts, organize text, and generate placeholders in Figma—all with one click.',
            software: 'VSCode',
            language: 'C#',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1XLN5zvA16i3xuADsTfvCcnWE855C4kjW/view?usp=drive_link',
            buttons: [
                {
                    button: (
                        <MoreButton link="https://www.figma.com/community/plugin/1412622701695231289/oink-styleup-the-all-in-one-design-tool" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        }
    ];

    const technical = [
        {
            // image: "https://i.ibb.co/DV8ZvHy/newton.png",
            image: "/assets/portfolio/art/newton.png",
            title: 'Code-Generated Model',
            problem: 'I scripted a Newton\'s Cradle in Maya with Python, using black and white elements inspired by Yin and Yang to symbolize balance and conflict.',
            software: 'VSCode & Maya',
            language: 'Python & Maya Expression',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1XLN5zvA16i3xuADsTfvCcnWE855C4kjW/view?usp=drive_link',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1XLN5zvA16i3xuADsTfvCcnWE855C4kjW/view?usp=drive_link" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1OTA=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/vJtm0kk/body.png",
            image: "/assets/portfolio/art/body.png",
            title: 'Kinect Body Tracking',
            problem: 'I used body tracking with Kinect to create dynamic backgrounds, displaying body outlines with layers of solid colors and numerical/symbolic styles.',
            software: 'Processing',
            language: 'Java',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1t9uzr3l6sXl_Eavwbklm7xOfNu0X5pfp/view?usp=drive_link',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1t9uzr3l6sXl_Eavwbklm7xOfNu0X5pfp/view?usp=drive_link" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1OTI=" />
                    ), isMain: true
                },
                {
                    button: (
                        // <PlayButton link="https://drive.google.com/file/d/1gzipIAilFCFRy2mVNYeK6RHYmdIwcMKA/view?usp=sharing" />
                        <PlayButton link="https://www.bilibili.com/video/BV14qHzeqEWH/" />
                    ), isMain: false
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/gdP4T86/chat.png",
            image: "/assets/portfolio/art/chat.png",
            title: 'Talk with ChatGPT',
            problem: 'I used the OpenAI API to convert spoken input into text, sending queries and displaying results visually in Processing.',
            software: 'Processing & VSCode',
            language: 'Java & Python',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1NOG7QC60TgDK5L6DoZ9FlrFyXuBJcJXA/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1NOG7QC60TgDK5L6DoZ9FlrFyXuBJcJXA/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1OTM=" />
                    ), isMain: true
                },
                {
                    button: (
                        // <PlayButton link="https://drive.google.com/file/d/1ccncGMAASML3ofRClJhch2rsAOB0hMVD/view?usp=sharing" />
                        <PlayButton link="https://www.bilibili.com/video/BV1K6HzevE5b/" />
                    ), isMain: false
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/p34yrZL/circle.png",
            image: "/assets/portfolio/art/circle.png",
            title: 'Circular Patterns Animation',
            problem: 'I created Java and Processing animations with a circular shape, featuring randomly generated layers from pre-designed samples.',
            software: 'Processing',
            language: 'Java',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1iIQqevdY9cl3adIJ9ZoorDxJv1gWv4bA/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1iIQqevdY9cl3adIJ9ZoorDxJv1gWv4bA/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1OTc=" />
                    ), isMain: true
                },
                {
                    button: (
                        // <PlayButton link="https://drive.google.com/file/d/1XJSuzyXQF_82f7Mvy18TPHyo9khOg-iv/view?usp=sharing" />
                        <PlayButton link="https://www.bilibili.com/video/BV1NkHzePEgG/" />
                    ), isMain: false
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/rwn2Qct/maze.png",
            image: "/assets/portfolio/art/maze.png",
            title: 'Maze Patterns Animation',
            problem: 'I created generative art with Python\'s Turtle module, exploring random pattern generation, building on my previous work in Java with Processing.',
            software: 'VSCode',
            language: 'Python',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1tvHQWsIvtRDfDvygdyz97iuN9mR7plJJ/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1tvHQWsIvtRDfDvygdyz97iuN9mR7plJJ/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1OTg=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/VYVqMtX/wire.png",
            image: "/assets/portfolio/art/wire.png",
            title: 'Dynamic Circuit Art',
            problem: 'I designed dynamic circuit art with interactive controls, using Processing for visuals and integrating Speech-to-Text and OpenAI APIs to display keywords within the circuit.',
            software: 'Processing & VSCode',
            language: 'Java & Python',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/10FDaMTsE1Jms8lRDtADJ0cg5-4NrNdyd/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/10FDaMTsE1Jms8lRDtADJ0cg5-4NrNdyd/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1OTk=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/pfgV8h5/space.png",
            image: "/assets/portfolio/art/space.png",
            title: 'Space and Time',
            problem: 'I fused generated content in Processing with manual designs from Illustrator, creating glowing lines and shapes in a dark space themed around \'space and time.\'',
            software: 'Processing & Adobe AI',
            language: 'Java',
            myRole: 'Developer & Graphic Designer',
            link: 'https://drive.google.com/file/d/1uHL486eiLruQ1J2byC1UkN5RH7oBDUsJ/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1uHL486eiLruQ1J2byC1UkN5RH7oBDUsJ/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU2MDI=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            // image: "https://i.ibb.co/SNFwrtW/console.png",
            image: "/assets/portfolio/art/console.png",
            title: 'Rhythm Game Console',
            problem: 'I designed, programmed, and assembled a game console with Arduino, featuring a rhythm game where players capture light entities and track scores.',
            software: 'Arduino',
            language: 'C++',
            myRole: 'Developer',
            link: 'https://drive.google.com/file/d/1ASAiVaLXquXQMla62bh25w1eFIbhRKU2/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1ASAiVaLXquXQMla62bh25w1eFIbhRKU2/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU2MDM=" />
                    ), isMain: true
                },
                {
                    button: (
                        <PlayButton link="https://www.bilibili.com/video/BV1NZmGYzEj4/?share_source=copy_web&vd_source=13409b2d62b58b65ba5b8ec926fd418d" />
                    ), isMain: false
                },
            ],
            backgroundColor: '#e2e2e2',
        },
    ];

    return (
        <div className='Program ShowSection'>
            <Gallery title="Frontend Coding" data={frontend} />
            <Gallery title="Plugin Coding" data={plugin} />
            <Gallery title="Technical Design" data={technical} />
        </div>
    );
};

export default ArtPage;