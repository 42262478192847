// App.js
// Module:
import React, { useState, useEffect, useDeferredValue } from "react";
// import { BrowserRouter as Router} from 'react-router-dom';
import { BasicContext } from './context/MainContext';
// Style:
import './App.scss';
// router
import RouterConfig from "./router/router"
// components
import ScrollToTop from './components/tools/ScrollToTop';
import Header from './components/sections/Header';
import Footer from './components/sections/Footer';
import FloatingMenu from "./components/buttons/FloatingMenu_Ant";
import CustomCursor from './components/others/Cursor';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const App = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPositionRaw, setScrollPositionRaw] = useState(0);
  const scrollPosition = useDeferredValue(scrollPositionRaw);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const headerHeight = 60;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      setScrollPositionRaw(currentScrollTop);

      if (currentScrollTop > lastScrollTop + 200) {
        setIsHeaderVisible(false);
        setLastScrollTop(currentScrollTop);
      } else if (currentScrollTop < lastScrollTop - 10) {
        setIsHeaderVisible(true);
        setLastScrollTop(currentScrollTop);
      }
    };

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  }, [lastScrollTop]);

  const mainStyle = {
    // paddingTop: `${headerHeight}px`,
    position: 'relative'
  };

  return (
    <>
      <ScrollToTop />
      <div className="App">
        <CustomCursor />
        <FloatingMenu />
        <Header windowWidth={windowWidth} isVisible={isHeaderVisible} />
        <div className="main-content" style={mainStyle}>
          <BasicContext.Provider value={{ headerHeight, windowHeight, windowWidth, scrollPosition }}>
            <RouterConfig />
          </BasicContext.Provider>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App;