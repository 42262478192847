/*
  The code below will generate the Profile page
  Contains Logo + Username + Inquiry remain
*/
// Module:
import React, { } from "react";
// import { SectionIndicatorContext } from './SectionIndicatorContext';
// components
import { ButtonMain } from '../../../components/buttons/Button_Common';
import LineDecoration from '../../../components/others/LineDecorations';
// assets
const arrow_1 = '/assets/icons/arrow_1.png'
const arrow_2 = '/assets/icons/arrow_2.png'
// const ladyIcon = '/assets/portfolio/frontend/letsplay/ladyIcon.png'
// const bombIcon = '/assets/portfolio/frontend/letsplay/bombIcon.png'

// static

const SignUpSection = () => {
  return (
    <>
      <div className="section-container">
        <div className="title-container">
          <h2>Guide</h2>
          <p>Our initial design was based on the iOS system, and users would use the software on their mobile devices.</p>
        </div>
        <div className="section-intro">
          <img src="https://i.ibb.co/9rtBsfG/132.png" alt="132" border="0" />
        </div>
      </div>

      <div className="guide-container">
        <div className="guide-content">
          <div className="guide-top">
            <img src="https://i.ibb.co/DLD75SJ/ladyIcon.png" alt="ladyIcon" border="0" />
            <h4>Game 1 introduction</h4>
            <p>In this game, a group of players take turns drawing playing cards from the app. Each card represents a different game. The represented game can be set by the players themselves or automatically set as a default. Once a card is drawn, the players engage in the game represented by that card.</p>
          </div>
          <div className="section-intro">
            <img src="https://i.ibb.co/Y0NKDMD/131.png" alt="131" border="0" />
          </div>
        </div>
        <div className="guide-content">
          <div className="guide-top">
            <img src="https://i.ibb.co/zHGTRRm/bombIcon.png" alt="bombIcon" border="0" />
            <h4>Game 2 introduction</h4>
            <p>Before the game begins, the app automatically selects five small bomb cards and two big bomb cards from the stack. During the game, players draw cards one by one. If the card they draw matches the number of any of the small bomb cards, or matches the number or kind of any of the big bomb cards, they must execute the corresponding punishment.</p>
          </div>
          <div className="section-intro">
            <img src="https://i.ibb.co/whJBkKw/133.png" alt="133" border="0" />
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="title-container">
          <h2>Development</h2>
        </div>
        <div className="section-intro">
          <img src="https://i.ibb.co/Ngqwvqf/134.png" alt="134" border="0" />
        </div>
      </div>

      <div className="section-container">
        <div className="title-container">
          <h2>UI design</h2>
        </div>
        <div className="section-intro">
          <img src="https://i.ibb.co/sK0ztSk/letsplay-design1.png" alt="letsplay-design1" border="0" />
        </div>
      </div>

      <div className="section-container">
        <div className="title-container">
          <h2>New problem</h2>
          <p>In the testing process, I found that as more and more players join, the distance between players also increases. When the number of players exceeds a certain threshold, providing an equal user experience for each player can be challenging due to limited screen size. For example, if the phone is placed in the middle, players need to get closer to see or rely on passing the device to each other to clearly view the results on the phone, which can potentially disrupt the flow of the game.</p>
        </div>
        <div className="section-intro">
          <img src="https://i.ibb.co/0XyYQZ4/letsplay-guide.png" alt="letsplay-watch-flow" border="0" />
        </div>
      </div>

      <div className="section-container">
        <div className="title-container">
          <h2>WatchOS Design</h2>
        </div>
        <div className="section-intro">
          <img src="https://i.ibb.co/XWdGXK6/letsplay-watch-flow.png" alt="letsplay-watch-design" border="0" />
        </div>
      </div>

      <div className="section-container">
        <div className="title-container">
          <h2>Prototypes</h2>
        </div>
        <div className="section-intro">
          <img className="midf" src="https://i.ibb.co/RcTj39k/letsplay-midf.png" alt="letsplay-watch-protptypes" border="0" />
          <img src="https://i.ibb.co/pz5B2TL/letsplay-highf.png" alt="letsplay-watch-protptypes" border="0" />
        </div>
      </div>

      <div className="section-container-mid">
        <div className="title-container">
          <h2>What to know more about me</h2>
        </div>
        <div className="buttonSection">
          <img className="arrowImg" src={arrow_2} alt="arrow_2" />
          <ButtonMain
            onClick={() => {
              window.open('https://drive.google.com/file/d/10oxxNJqBz6b2MarF7uejr7INLwM02zC4/view?usp=sharing', '_blank');
            }}
          >
            <span style={{ padding: '0 10px', fontWeight: 'bold' }}>More</span>
          </ButtonMain>
          <img className="arrowImg" src={arrow_1} alt="arrow_1" />
        </div>
      </div>
    </>
  );
}

export default SignUpSection;