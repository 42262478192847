/*
  The code below will generate the Profile page
  Contains Logo + Username + Inquiry remain
*/
// Module:
import React, { useState, useEffect, useContext } from "react";
import { SectionIndicatorContext } from '../../../context/MainContext';
// components
import Progress from '../../../components/tools/Progress';
import LineDecoration from '../../../components/others/LineDecorations';
// assets
const logo_plain = '/assets/portfolio/frontend/letsplay/logo_plain.png'
const logo_highlight_01 = '/assets/portfolio/frontend/letsplay/logo_highlight_01.png'
const logo_highlight_04 = '/assets/portfolio/frontend/letsplay/logo_highlight_02.png'
const logo_highlight_03 = '/assets/portfolio/frontend/letsplay/logo_highlight_03.png'
const logo_highlight_02 = '/assets/portfolio/frontend/letsplay/logo_highlight_04.png'
// static
// const sectionIndicatorContext = React.createContext();

// 文字部分
const TextBlock = props => {
    const title = props.title;
    const body = props.body;
    return (
        <div className='textBlock'>
            <div>
                <h3>{title}</h3>
                <p>{body}</p>
            </div>
        </div>
    );
}

// 文字部分 + 装饰部分
const ProgressPart = ({ myId, progress, data }) => {
    const [isFocus, setIsFocus] = useState(false);
    const [isEnding, setIsEnding] = useState(false);
    const currentId = data.currentId;
    const center = data.center;
    const endDeadlinePart = data.endDeadlinePart;
    const title = data.title;
    const body = data.body;
    const myHeight = data.myHeight; //test
    useEffect(() => {
        if (myId === currentId) {
            setIsFocus(true);
            setIsEnding((progress >= endDeadlinePart) ? true : false);
        } else {
            setIsFocus(false);
        }
    }, [myId, currentId, progress, endDeadlinePart, isFocus]);

    const getTransformEntries = () => {
        return data.transform ? Object.entries(data.transform) : [['', 0]];
    };
    const [[transformKey, transformValue]] = getTransformEntries();
    const calculateTransform = () => {
        if (transformKey.includes('rotate')) {
            return `${transformKey}(${progress * transformValue}deg)`; // Rotation transform (in degrees)
        } else {
            return `${transformKey}(${progress * transformValue}px)`; // Other transforms (in pixels)
        }
    };
    const calculateOpacity = (progress, start, endOffset) => {
        if (progress >= endDeadlinePart - endOffset) {
            return Math.min(1, (80 - progress) / (100 - endDeadlinePart + endOffset));
        } else if (progress >= start && progress <= endDeadlinePart - endOffset) {
            return 1;
        } else if (progress < start) {
            return Math.max(0, progress / start);
        } else {
            return 0;
        }
    };
    const containerStyle = {
        height: `${myHeight}px`,
        display: isFocus ? '' : 'none',
        opacity: isFocus ? calculateOpacity(progress, 20, 5) : 0,
        position: (!isEnding && isFocus) ? 'fixed' : 'absolute',
        top: (!isEnding && isFocus) ? `${center + 30}px` : '',
        bottom: isEnding ? `${center - 60}px` : '',
        transform: calculateTransform(),
    }
    return (
        <div className="progressPart" style={containerStyle}>
            <TextBlock title={title} body={body} />
        </div>
    );
}

// 完整滑动板块
const ProgressSection = ({ headerHeight, section1Height, section2Height }) => {
    const { activeSection, pastSection, scrollPosition, windowHeight } = useContext(SectionIndicatorContext);
    const [elId, setElId] = useState(0);
    const [elProgress, setElProgress] = useState(0);
    const [seProgress, setSeProgress] = useState(0);
    // const contentData = [3000, 3000, 3000, 3000];
    const contentHeight = section2Height / 4;
    const bottomEdge4 = headerHeight + section1Height + contentHeight * 3;
    const bottomEdge3 = headerHeight + section1Height + contentHeight * 2;
    const bottomEdge2 = headerHeight + section1Height + contentHeight;
    const startEdge = scrollPosition - section1Height - headerHeight;

    useEffect(() => {
        if (activeSection === 'section2') {
            setElId((pastSection === 'section1' ? 1 : 4))
            if (scrollPosition >= bottomEdge4) {
                setElId(4)
                setElProgress((scrollPosition - bottomEdge4) / contentHeight * 100)
            } else if (scrollPosition >= bottomEdge3) {
                setElId(3)
                setElProgress((scrollPosition - bottomEdge3) / contentHeight * 100)
            } else if (scrollPosition >= bottomEdge2) {
                setElId(2)
                setElProgress((scrollPosition - bottomEdge2) / contentHeight * 100)
            } else {
                setElId(1)
                setElProgress(startEdge / contentHeight * 100)
            }
            setSeProgress(startEdge / section2Height * 100)
        } else {
            setElId(0)
            setSeProgress(0);
        }
    }, [activeSection, pastSection, scrollPosition]);

    const calculateOpacity = (progress, start, end) => {
        if (progress >= 100 || progress <= 0) { return 0; }
        else if (progress > end) {
            return 1 - (progress - end) / (100 - end);
        } else if (progress >= start && progress <= end) {
            return 1;
        } else if (progress < start) {
            return progress / start;
        }
    };
    // const opacity = calculateOpacity(progress);
    const rotationDegrees = (seProgress / 100) * 360;
    const endDeadlineSection = ((section2Height - windowHeight) / section2Height * 100)
    const leftLogoStyle = {
        opacity: calculateOpacity(seProgress, 5, 80),
        top: `${windowHeight / 2 - 120}px`,
        transform: (seProgress >= endDeadlineSection) ? `translateX(${-45 - (seProgress - endDeadlineSection) / (100 - endDeadlineSection) * 100}%) translateY(${-(seProgress - endDeadlineSection) / (100 - endDeadlineSection) * 100 * 10}px) rotate(${rotationDegrees}deg)` : `translateX(-45%) rotate(${rotationDegrees}deg)`,
    };
    const leftTitleStyle = {
        opacity: calculateOpacity(seProgress, 5, 90),
        transform: (seProgress >= endDeadlineSection) ? `translateY(${-(seProgress - endDeadlineSection) / (100 - endDeadlineSection) * 100 * 10}px)` : '',
    };
    const highlightIconStyle = (id) => {
        const highLightStyle = {
            visibility: elId === id ? 'visible' : 'hidden',
        };
        return highLightStyle;
    };
    const dateGenerator = ({ myHeight, title, body, transform = { 'translateY': -0.8 } }) => {
        const data = {
            currentId: elId,
            windowHeight: windowHeight,
            center: windowHeight / 2,
            endDeadlinePart: ((myHeight - windowHeight) / myHeight * 100),
            title: title,
            body: body,
            transform: transform,
        };
        return data
    };
    const data1 = dateGenerator({ myHeight: contentHeight, title: 'Background', body: `In this project, I designed and developed an application with a mini social game, targeting young people who enjoy socializing and gaming. They don't need to carry around cards or boards; all they need is an iPhone or iPad with this app installed to play multiplayer games.` });
    const data2 = dateGenerator({ myHeight: contentHeight, title: 'Problems of in-Person social entertainment', body: 'When playing face-to-face social games with friends, we often need to consider preparing physical board games. However, this can sometimes be cumbersome, especially in situations with limited space or when carrying physical games is challenging.' });
    const data3 = dateGenerator({ myHeight: contentHeight, title: 'How might we...', body: '“How can we use a single device to provide a social entertainment experience for a group of people?”' });
    const data4 = dateGenerator({ myHeight: contentHeight, title: 'Key Takeaways', body: 'I practiced problem-solving skills and became a self-directed learner through this project. The experience provided me with a way of thinking to build a multi-functional app with a clear and constructive guideline. Since I worked as both the designer and the developer, I had the opportunity to experience both roles and understand more of how they collaborate.' });

    return (
        <>
            <div className="sectionTitle" style={leftTitleStyle} >
                <h3>Overview</h3>
                <LineDecoration offsetX={0} width='40px' backgroundColor='#282f40'/>
            </div>
            <div className="leftFixedSection">
                <div className="leftLogoSection" style={leftLogoStyle} >
                    <img src={logo_plain} alt="Logo" className="deco-logo" />
                    <img src={logo_highlight_01} alt="Logo" className="deco-logo-highlight" style={highlightIconStyle(1)} />
                    <img src={logo_highlight_02} alt="Logo" className="deco-logo-highlight" style={highlightIconStyle(2)} />
                    <img src={logo_highlight_03} alt="Logo" className="deco-logo-highlight" style={highlightIconStyle(3)} />
                    <img src={logo_highlight_04} alt="Logo" className="deco-logo-highlight" style={highlightIconStyle(4)} />
                </div>
            </div>
            <div>
                <Progress data={data1} myId={1} progress={elProgress} >
                    <ProgressPart />
                </Progress>

                <Progress data={data2} myId={2} progress={elProgress} >
                    <ProgressPart />
                </Progress>

                <Progress data={data3} myId={3} progress={elProgress} >
                    <ProgressPart />
                </Progress>

                <Progress data={data4} myId={4} progress={elProgress} >
                    <ProgressPart />
                </Progress>
            </div>
        </>
    );
};

export default ProgressSection;