import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// import zhCN from 'antd/es/locale/zh_CN';
// import enUS from 'antd/locale/en_US';
import { ConfigProvider } from 'antd';

import { BrowserRouter } from "react-router-dom";

// import { Provider } from 'react-redux'
// import store from './redux/store';

// import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* <Provider store={store}> */}
    <ConfigProvider
      theme={{ token: { 
        colorPrimary: '#FBA92C',
        colorBgElevated: '#e2e2e2',
        // colorFillContent: '#282f40',
        // colorPrimaryHover: '#748D92'
        },
      }}
    >
      <App />
    </ConfigProvider>
    {/* </Provider> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
