import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
// pages
import HomeNew from "../pages/home/HomeNew";
import Lady from "../pages/frontend/lady/letsPlay";

import About from '../pages/about/AboutPage';
import ArtPage from '../pages/art/ArtPage';
import UiuxPage from '../pages/uiux/UiuxPage';
import AIPage from '../pages/ai/SDWebui';
import QuickUploadPage from '../pages/ai/QuickUpload';
import Error404 from '../components/others/Error';

const Router = props => {
    return useRoutes([
        {
            path: '/',
            element: <HomeNew />,
        },
        {
            path: '/design',
            element: <UiuxPage />,
        },
        {
            path: '/program',
            children: [
                {
                    element: <ArtPage />,
                    index: true,
                },
                {
                    path: 'letsplay',
                    element: <Lady />,
                },
            ],

        },
        {
            path: '/about',
            element: <About />,
        },
        {
            path: '*',
            element: <Error404 />, // This will match any undefined route
        },
        {
            path: '/img2img',
            element: <AIPage />,
        },
        {
            path: '/quickupload',
            element: <QuickUploadPage />,
        },
    ]
    );
}

export default Router;