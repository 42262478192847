import React, { useState, useRef, useEffect } from 'react';

const QuickUpload = () => {
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };
    const handleUploadImage = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImageURL(URL.createObjectURL(file));
    };
    useEffect(() => {
        handleFileInputClick();
        console.log('here');
    }, []);

    return (
        <div style={{width: '100vw', height: '100vh', position: 'fixed', backgroundColor: 'white', zIndex: '10000000'}}>
            <input
                type="file"
                accept="image/*"
                onChange={handleUploadImage}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default QuickUpload;