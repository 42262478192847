import { LinkedinFilled, InstagramFilled, MoreOutlined, DownCircleOutlined, RobotOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FloatButton } from 'antd';

const FloatingMenuAnt = () => {
    const navigate = useNavigate();
    const handleLinkClick = (path) => (event) => {
        navigate(path);
    };

    return (
        <>
            <FloatButton.Group
                trigger="hover"
                type="primary"
                // shape='square'
                style={{
                    right: 24,
                    bottom: 30,
                }}
                closeIcon={<DownCircleOutlined />}
                icon={<MoreOutlined
                    style={{
                        color: '#fff',
                        fontSize: '18px',
                    }}
                />}
            >
                <FloatButton
                    href="https://www.linkedin.com/in/haoming-lang-026207201/"
                    target="_blank" rel="noopener noreferrer"
                    // type='primary'
                    tooltip={<div>Linkedin</div>}
                    icon={<LinkedinFilled
                        style={{
                            color: '#748D92',
                        }}
                    />}
                />
                <FloatButton
                    href="https://www.instagram.com/haoming101000101?igsh=MTNwYTNpZzh4MGxheg%3D%3D&utm_source=qr"
                    target="_blank" rel="noopener noreferrer"
                    // type='primary'
                    tooltip={<div>Instagram</div>}
                    icon={<InstagramFilled
                        style={{
                            color: '#748D92',
                        }}
                    />}
                />
                {/* <FloatButton type="primary" icon={<CommentOutlined />} onClick={handleLinkClick('/contact')} /> */}
                <FloatButton.BackTop />
            </FloatButton.Group>
        </>
    );
};

export default FloatingMenuAnt;