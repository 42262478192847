/*
  The code below will generate the Profile page
  Contains Logo + Username + Inquiry remain
*/
// Module:
import React, { useState, useEffect, useContext } from "react";
import { SectionIndicatorContext } from '../../../context/MainContext';
// components
import Progress from '../../../components/tools/Progress';
import StepProgressBar from '../../../components/others/StepProgressBar';
import LineDecoration from '../../../components/others/LineDecorations';
// assets

const lady_1 = "https://i.ibb.co/PWLHJVq/lady-Video-1.gif";
const lady_2 = "https://i.ibb.co/LxJfB4J/lady-Video-2.gif";
const lady_3 = "https://i.ibb.co/7QwW0yH/lady-Video-3.gif";
const lady_4 = "https://i.ibb.co/5r5CC09/lady-Video-4.gif";
const lady_5 = "https://i.ibb.co/T4R1Tb2/lady-Video-5.gif";
const lady_sitemap = "https://i.ibb.co/QkbsYf3/lady-sitemap.png";

// 文字部分
const TextBlock = props => {
    const title = props.title;
    const body = props.body;
    return (
        <div className='textBlock'>
            <h3>{title}</h3>
            <p>{body}</p>
        </div>
    );
}

// 文字部分 + gif部分
const ProgressPart = ({ myId, progress, data }) => {
    const [isEnding, setIsEnding] = useState(false);
    const currentId = data.currentId;
    // const center = data.center;
    const endDeadlinePart = data.endDeadlinePart;
    const section3Height = data.section3Height;
    const textContent = [
        { title: 'IOS SiteMap', body: 'Say goodbye to the tedium of manually entering the same information repeatedly on various job application websites.', gif: [lady_sitemap], size: ["", "290"] },
        { title: 'IOS', body: 'ApplyAI ensures accuracy by automatically transferring your details from a master profile, minimizing the risk of typos and discrepancies.', gif: [lady_1, lady_2, lady_3], size: ["112", "198"] },
        { title: 'WatchOS SiteMap', body: 'Tailor your applications effortlessly by selecting those questions, showcasing your skills and experiences that align with each job description.', gif: [lady_4, lady_5], size: ["220", "200"] },
    ];
    const currentTextContent = textContent[currentId - 1] ?? { title: '', body: '', gif: [] };
    const { title, body, gif, size } = currentTextContent;
    useEffect(() => {
        setIsEnding((progress >= endDeadlinePart) ? true : false);
    }, [currentId, progress, endDeadlinePart]);

    const containerStyle = {
        display: currentId === 0 ? 'none' : '',
        position: isEnding && currentId === 3 ? 'relative' : 'sticky',
        top: isEnding && currentId === 3 ? `${section3Height - 700}px` : '210px',
    }
    const textStyle = {
        transform: `translateY(${progress * -0.1 - 30}px)`,
    }
    const imageWidth = gif.length > 0 ? `${100 / gif.length}%` : '100%';
    return (
        <div className="progressPart" style={containerStyle}>
            <div className="textPart" style={textStyle}>
                <TextBlock title={title} body={body} />
            </div>
            <div className="gifs">
                {gif.map((item, index) => (
                    <img key={index} loading="lazy" src={item} alt="stepGif" border="0" style={{ width: imageWidth }} />
                ))}
            </div>
        </div>
    );
}

// 完整滑动板块
const StepSection = ({ headerHeight, section1Height, section2Height, section3Height }) => {
    const { activeSection, pastSection, scrollPosition, windowHeight } = useContext(SectionIndicatorContext);
    const [elId, setElId] = useState(0);
    const [elProgress, setElProgress] = useState(0);
    const [seProgress, setSeProgress] = useState(0);
    const contentHeight = section3Height / 3;
    const bottomEdge3 = headerHeight + section1Height + section2Height + contentHeight * 2;
    const bottomEdge2 = headerHeight + section1Height + section2Height + contentHeight;
    const bottomEdge1 = headerHeight + section1Height + section2Height;
    const startEdge = scrollPosition - section1Height - section2Height - headerHeight;

    useEffect(() => {
        if (activeSection === 'section3') {
            setElId((pastSection === 'section2' ? 1 : 3));
            if (scrollPosition >= bottomEdge3) {
                setElId(3);
                handleStepChange(2);
                setElProgress((scrollPosition - bottomEdge3) / contentHeight * 100);
            } else if (scrollPosition >= bottomEdge2) {
                setElId(2);
                handleStepChange(1);
                setElProgress((scrollPosition - bottomEdge2) / contentHeight * 100);
            } else {
                setElId(1);
                handleStepChange(0);
                setElProgress(startEdge / contentHeight * 100);
            }
            setSeProgress(startEdge / section3Height * 100);
        } else {
            setElId(0);
            handleStepChange(-1);
            setSeProgress(0);
        }
    }, [activeSection, pastSection, scrollPosition]);

    const calculateOpacity = (progress, start, end) => {
        if (progress >= 100 || progress <= 0) { return 0; }
        else if (progress > end) {
            return 1 - (progress - end) / (100 - end);
        } else if (progress >= start && progress <= end) {
            return 1;
        } else if (progress < start) {
            return progress / start;
        }
    };
    const endDeadlineSection = ((section3Height - windowHeight) / section3Height * 100)
    const leftTitleStyle = {
        opacity: calculateOpacity(seProgress, 3, 90),
        transform: (seProgress >= endDeadlineSection) ? `translateY(${-(seProgress - endDeadlineSection) / (100 - endDeadlineSection) * 100 * 10}px)` : '',
    };
    const bottomProgressStyle = {
        display: activeSection === 'section3' ? '' : 'none',
        position: seProgress >= endDeadlineSection ? 'relative' : 'sticky',
        top: seProgress >= endDeadlineSection ? `${section3Height - 160}px` : '640px',
    };

    const dateGenerator = ({ myHeight, section3Height }) => {
        const data = {
            currentId: elId,
            windowHeight: windowHeight,
            center: windowHeight / 2,
            section3Height: section3Height,
            endDeadlinePart: ((myHeight - windowHeight) / myHeight * 100),
        };
        return data
    };
    const data1 = dateGenerator({ myHeight: contentHeight, section3Height: section3Height });

    const stepNum = 3;
    const yPositions = [bottomEdge1 + 260, bottomEdge2, bottomEdge3];
    const [percentWidth, setPercentWidth] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const handleStepChange = (newStep) => {
        setCurrentStep(newStep);
        setPercentWidth(newStep * (100 / (stepNum - 1)));
    };

    return (
        <>
            <div className="placeholder"></div>
            <div className="sectionTitle" style={leftTitleStyle} >
                <h3>Output</h3>
                <LineDecoration offsetX={0} width='40px' backgroundColor="#282f40"/>
            </div>
            <div style={{ margin: '0 auto', width: '100%' }}>
                <div className="bottomFixedSection" style={bottomProgressStyle}>
                    <StepProgressBar
                        stepNum={stepNum}
                        currentStep={currentStep}
                        percentWidth={percentWidth}
                        yPositions={yPositions}
                        onStepChange={handleStepChange}
                        width="380px"
                    />
                </div>
                <div className="contentSection">
                    <Progress data={data1} myId={1} progress={elProgress} style={{ height: `${section3Height}px`, backgroundColor: '', position: 'relative' }}>
                        <ProgressPart />
                    </Progress>
                </div>
            </div>
        </>
    );
}

export default StepSection;