import React from 'react';
import PropTypes from 'prop-types';
import './StepProgressBar.scss';

const StepProgressBar = ({
  stepNum,
  width = '200px',
  currentStep = 0,
  percentWidth = 0,
  yPositions = [],
  onStepChange
}) => {
  // Generate step indicators based on stepNum
  const steps = Array.from({ length: stepNum }, (_, index) => index);

  const handleStepClick = (index) => {
    if (yPositions && yPositions.length > index && typeof yPositions[index] === 'number') {
      window.scrollTo({
        top: yPositions[index],
        behavior: 'auto'
      });
    }
    if (onStepChange) {
      onStepChange(index);
    }
  };

  return (
    <div className="StepProgressBar" style={{ width }}>
      <div className="progress">
        <div className="percent" style={{ width: `${percentWidth}%` }}></div>
      </div>
      <div className="steps">
        {steps.map(index => (
        <div key={index} className='node'>
          <p className={`text ${index <= currentStep ? 'selected' : ''}`} >Step {index+1}</p>
          <div 
            className={`step ${index <= currentStep ? 'selected' : ''}`}
            onClick={() => handleStepClick(index)}
          ></div>
        </div>
        ))}
      </div>
    </div>
  );
};

StepProgressBar.propTypes = {
  stepNum: PropTypes.number.isRequired,
  width: PropTypes.string,
  currentStep: PropTypes.number,
  percentWidth: PropTypes.number,
  yPositions: PropTypes.arrayOf(PropTypes.number),
  onStepChange: PropTypes.func.isRequired
};

export default StepProgressBar;