import React from 'react';
// import WorkDisplay from '../../components/sections/WorkDisplay';
import { MoreButton } from '../../components/buttons/Button_Common';
import Gallery from '../../components/sections/Gallery';
import '../../styles/design.scss';
const UiuxPage = () => {
    // useEffect(() => {
    //     window.location.reload();
    // }, []);
    const contentWork = [
        {
            image: '/assets/portfolio/uiux/bifrost.png',
            // image: "https://i.ibb.co/g476v23/bifrost.png",
            title: 'Bifrost',
            problem: 'Bifrost is an app that helps users trade digital assets cross-platform and create an NFT community to showcase their assets.',
            targetUser: 'Users struggle to trade virtual items across different platforms. NFT platforms also lack engaging features for younger users and effective promotion for new creators.',
            myRole: 'Researcher & Video Prototyper',
            link: 'https://drive.google.com/file/d/10LmhKYstR1diS2Fq9nFa904UWTn8evGY/view',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/10LmhKYstR1diS2Fq9nFa904UWTn8evGY/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1NTc=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: '/assets/portfolio/uiux/drift.png',
            // image: "https://i.ibb.co/2Fd3jW4/drift.png",
            title: 'Drift',
            problem: 'Drift is a mobile app that connects users with real-world objects and builds an online community around them.',
            targetUser: 'People are becoming increasingly isolated and disconnected from their surroundings due to high smartphone usage and poor real-world interaction opportunities.',
            myRole: 'UI/UX Designer',
            link: 'https://drive.google.com/file/d/1EaHu1Mnj5i49ubCMnuwSFurLmDOlm1l8/view',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1EaHu1Mnj5i49ubCMnuwSFurLmDOlm1l8/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1NjE=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: '/assets/portfolio/uiux/loose.png',
            // image: "https://i.ibb.co/2vxr6wd/loose.png",
            title: 'Loose',
            problem: 'It is a helpful and informative app for people who are going through a bad mood.',
            targetUser: 'People struggle to cope with the emotional distress and loneliness after a breakup, often not knowing who to talk to or how to move on.',
            myRole: 'UI/UX Designer',
            link: 'https://drive.google.com/file/d/1F_YexKrUhQX9Z9Hjj27h8hmJfpZgE7Gr/view',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1F_YexKrUhQX9Z9Hjj27h8hmJfpZgE7Gr/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1NjI=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: '/assets/portfolio/uiux/nft.png',
            // image: "https://i.ibb.co/SmjsGFn/nft.png",
            title: 'NFTQuest',
            problem: 'It is a NFT collector and community platform that associate with on-site experience.',
            targetUser: 'Current NFT platforms lack engaging real-world connections and experiences, limiting the potential for social interaction and discovery.',
            myRole: 'UI/UX Designer',
            link: 'https://drive.google.com/file/d/1Itgm59iGLNOjHOUm1vakYyRPi5Vynyml/view',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1Itgm59iGLNOjHOUm1vakYyRPi5Vynyml/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1NjU=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: '/assets/portfolio/uiux/parking.png',
            // image: "https://i.ibb.co/nPVRckC/parking.png",
            title: 'Parking Hunter',
            problem: 'A safe, affordable, and efficient platform for renting parking spaces in urban cities in China.',
            targetUser: 'Finding parking in urban areas is difficult due to a shortage of parking spaces and inefficient space utilization.',
            myRole: 'UI/UX Designer',
            link: 'https://drive.google.com/file/d/1sgQ0moH6auX8WDM_pstquEigv75hgoU8/view',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1sgQ0moH6auX8WDM_pstquEigv75hgoU8/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1Njg=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: '/assets/portfolio/uiux/telepath.png',
            // image: "https://i.ibb.co/syxN7Bn/telepath.png",
            title: 'Telepath',
            problem: 'This is a reusable framework for a museum app that involves immersive experiences.',
            targetUser: 'Museums struggle to engage diverse and modern audiences, making it difficult for people to connect with and appreciate art.',
            myRole: 'UI/UX Designer',
            link: 'https://drive.google.com/file/d/1BAvgjEaW0B-0izTjo-yrNeLxSFh16V7j/view',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1BAvgjEaW0B-0izTjo-yrNeLxSFh16V7j/view" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1Njk=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
        {
            image: '/assets/portfolio/uiux/xreal.png',
            // image: "https://i.ibb.co/Q6Sj6Wh/xreal.png",
            title: 'Nebula',
            problem: 'While working at the AR smart glasses company Xreal, I was assigned the task of redesigning the mobile app.',
            targetUser: 'The old UI design of the Nebula app is unattractive, and the app\'s content needs to be updated with several new features.',
            myRole: 'UI/UX Designer',
            link: 'https://drive.google.com/file/d/1a0eauu_I1gCwMDohL1rViK5UodYUOf_s/view?usp=sharing',
            buttons: [
                {
                    button: (
                        // <MoreButton link="https://drive.google.com/file/d/1a0eauu_I1gCwMDohL1rViK5UodYUOf_s/view?usp=sharing" />
                        <MoreButton link="https://pdfjm.cn/api/pdf/pdf?id=NDU1NzI=" />
                    ), isMain: true
                },
            ],
            backgroundColor: '#e2e2e2',
        },
    ];

    return (
        <div className='Design ShowSection'>
            <Gallery title="UI/UX Design" data={contentWork} />
        </div>
    );
};

export default UiuxPage;




// <div className="my-works" style={{ display: "grid", justifyContent: "center", padding: "80px 14px 60px 14px" }}>
//     {contentWork.map((work, index) => (
//         <WorkDisplay
//             key={index}
//             image={work.image}
//             title={work.title}
//             problem={work.problem}
//             targetUser={work.targetUser}
//             myRole={work.myRole}
//             link={work.link}
//             buttons={work.buttons}
//             backgroundColor={work.backgroundColor}
//         />
//     ))}
// </div>