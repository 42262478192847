import React from 'react';
import PropTypes from 'prop-types';
import './LineDecorations.scss';

const LineDecoration = ({ offsetX = 0, width = '30%', height = '3px', backgroundColor = 'white', marginTop='5px', marginBottom='10px' }) => {
    return (
        <div
            className="line-decoration"
            style={{ transform: `translateX(${offsetX}px)`, width: width, height: height, backgroundColor: backgroundColor, marginTop: marginTop, marginBottom: marginBottom }}
        ></div>
    );
};

LineDecoration.propTypes = {
    offsetX: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default LineDecoration;
