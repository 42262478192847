/*
  The code below will generate the navigation bar
  Contains Logo + menu + profile button + helper button
*/
// Module:
import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
// components
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
// Styles:
import '../../styles/header.scss';
// Assets:
const colorLogo = '/pig_1.jpeg';

const Header = ({ windowWidth, isVisible }) => {
	const nodeRef = useRef(null); // Create a ref for the transitioning node
	const [state, dispatch] = useReducer(toggleReducer, { isMenuOpen: false, isReadyShowed: false });
	const navigate = useNavigate();
	const [timerId, setTimerId] = useState(null);
	const location = useLocation();

	function toggleReducer(state, action) {
		switch (action.type) {
			case 'toggle':
				return { ...state, isMenuOpen: !state.isMenuOpen };
			case 'setReadyShowed':
				return { ...state, isReadyShowed: action.payload };
			case 'RESET':
				return { isMenuOpen: false, isReadyShowed: false };
			default:
				throw Error('Unknown action: ' + action.type);
		}
	}
	const toggleMenu = () => {
		dispatch({ type: 'toggle' });
	};
	const handleLinkClick = (path) => (event) => {
		event.preventDefault(); // Prevent default link behavior
		dispatch({ type: 'RESET' }); // Reset the menu state
		navigate(path); // Navigate programmatically using `useNavigate`
	};
	const isSelected = (path) => location.pathname === path;

	// useEffect for Timer
	useEffect(() => {
		if (state.isMenuOpen) {
			// Disable scrolling
			document.body.style.overflow = 'hidden';
			const id = setTimeout(() => {
				dispatch({ type: 'setReadyShowed', payload: true });
			}, 400);
			setTimerId(id);
		} else {
			// Enable scrolling
			document.body.style.overflow = 'auto';
			dispatch({ type: 'setReadyShowed', payload: false });
			if (timerId) {
				clearTimeout(timerId);
			}
		}
	}, [state.isMenuOpen]);
	useEffect(() => {
		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [timerId]);

	useEffect(() => {
		if (state.isMenuOpen && windowWidth > 768) {
			dispatch({ type: 'toggle' });
		}
	}, [windowWidth]);

	return (
		<header className={'header'}>
			<div className={`header-container ${isVisible ? 'visible' : 'hidden'}`}>
				<Link to="/" className="left no-style-link">
					<img className='logo' src={colorLogo} alt='color logo' />
				</Link>

				<div className='center'>
					<ul className="list-box">
						<li className={isSelected('/design') ? 'selected' : ''}>
							<Link to="/design">Designer</Link>
						</li>
						<li className={isSelected('/program') ? 'selected' : ''}>
							<Link to="/program">Programmer</Link>
						</li>
						{/* <li className={isSelected('/art') ? 'selected' : ''}>
							<Link to="/art">Art</Link>
						</li> */}
						<li className={isSelected('/about') ? 'selected' : ''}>
							<Link to="/about">About</Link>
						</li>
					</ul>
				</div>

				<div className="right">
					{/* <div className="buttons-right">
					<ul className="list-box">
						<li className={isSelected('/about') ? 'selected' : ''}>
							<Link to="/about">About</Link>
						</li>
					</ul>
				</div> */}
					<div className="hamburger" onClick={toggleMenu} >
						{state.isMenuOpen ? <CloseOutlined className='ham-icon' style={{ color: '#e2e2e2', fontSize: '20px' }} /> : <MenuOutlined className='ham-icon' style={{ color: '#282f40' }} />}
					</div>
					<CSSTransition
						nodeRef={nodeRef} in={state.isMenuOpen} timeout={600}
						classNames="my-header"
						unmountOnExit
					>
						<div ref={nodeRef}>
							<ul className="popupMenu" style={{ display: state.isMenuOpen && state.isReadyShowed ? '' : 'none' }}>
								<li className={isSelected('/') ? 'selected' : ''}>
									<a onClick={handleLinkClick('/')}>Home</a>
								</li>
								<li className={isSelected('/design') ? 'selected' : ''}>
									<a onClick={handleLinkClick('/design')}>Designer</a>
								</li>
								{/* <li className={isSelected('/frontend') ? 'selected' : ''}>
									<a onClick={handleLinkClick('/frontend')}>Frontend</a>
								</li> */}
								<li className={isSelected('/program') ? 'selected' : ''}>
									<a onClick={handleLinkClick('/program')}>Programmer</a>
								</li>
								<li className={isSelected('/about') ? 'selected' : ''}>
									<a onClick={handleLinkClick('/about')}>About</a>
								</li>
							</ul>
						</div>
					</CSSTransition>
				</div>
			</div>
		</header>
	);
};

export default Header;