import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../../styles/gallery.scss';

// gsap.registerPlugin(ScrollTrigger);

const Gallery = ({ title, data }) => {
    const containersRef = useRef([]);

    useEffect(() => {
        const animations = containersRef.current.map((container) => {
            return gsap.fromTo(
                container,
                { autoAlpha: 0, y: 0 }, // Start with opacity 0 and translateY 50px
                {
                    autoAlpha: 1, // Animate to opacity 1
                    y: 0, // Animate to translateY 0
                    duration: 0.8,
                    scrollTrigger: {
                        trigger: container,
                        start: "top 80%", // Start the animation when the container is 80% from the top of the viewport
                        toggleActions: "play none none none", // Animation will reverse when scrolling back up
                    },
                }
            );
        });

        // Cleanup function to kill ScrollTriggers on unmount
        return () => {
            animations.forEach(animation => animation.scrollTrigger && animation.scrollTrigger.kill());
        };
    }, []);

    return (
        <div className="wrapper">
            <h1>{title}</h1>
            <div className="cols">
                {data.map((work, index) => (
                    <div
                        className="col"
                        key={index}
                        ontouchstart={() => this.classList.toggle('hover')}
                        ref={(el) => (containersRef.current[index] = el)} // Add each container to the ref array
                    >
                        <div className="container">
                            <div className="front" style={{ backgroundImage: `url(${work.image})` }}>
                                <div className="inner">
                                    <p>{work.title}</p>
                                    <div>
                                        <h5>MY ROLE</h5>
                                        <span>{work.myRole}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="back">
                                <div className="inner">
                                    <div>
                                        <h5>OVERVIEW</h5>
                                        <span>{work.problem}</span>
                                    </div>
                                    <div className='buttons'>
                                        {work.buttons.map((button, index) => (
                                            <div key={index}>
                                                {button.button}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

Gallery.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            problem: PropTypes.string.isRequired,
            myRole: PropTypes.string.isRequired,
            buttons: PropTypes.arrayOf(
                PropTypes.shape({
                    button: PropTypes.node.isRequired,
                    isMain: PropTypes.bool,
                })
            )
        })
    )
};

export default Gallery;