// Module:
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { RightOutlined, ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import useScrollSnap from "react-use-scroll-snap";
// import { Link } from 'react-router-dom';
import { useGSAP } from '@gsap/react';

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
// Style:
import '../../styles/homeNew.scss';
// Assets:
const scad_img = "/assets/portfolio/scad.jpg";
const ua_img = "/assets/portfolio/ua.jpg";
const lady_1 = "/assets/portfolio/home-lady-1.png";
const plug_1 = "/assets/portfolio/cover.png";
const plug_2 = "/assets/portfolio/cover-figma-web.png";
const vs_1 = "/assets/portfolio/uiux/vision.png";
const post_1 = "/assets/portfolio/home-post-1.png";
// const lady_1 = "https://i.ibb.co/cQSP9rq/home-lady-1.png"
// const vs_1 = "https://i.ibb.co/PFF3qtg/bmw-vs-1.png";

// const lady_link = "https://pdfjm.com/api/pdf/pdf?id=NDU1Nzg=";
// const vs_link = '/program/BMW';

const HomePage = () => {
    const ref = useRef([]);
    const textRef = useRef();
    const textMinorRef = useRef();
    const sectionRef = useRef(null);
    const firstSectionRef = useRef(null);
    const mainSectionRef = useRef(null);
    const arrowRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);

    useScrollSnap({ ref: sectionRef });

    const addtoRefs = (el) => {
        if (el && !ref.current.includes(el)) {
            ref.current.push(el);
        }
    }

    const handleScroll = () => {
        const scrolledFromTop = window.scrollY;
        const viewportHeight = window.innerHeight;
        const fullHeight = document.documentElement.scrollHeight;

        const isBottom = scrolledFromTop + viewportHeight >= fullHeight - 1;
        const isScrolledUpMoreThan20px = scrolledFromTop < fullHeight - viewportHeight - 20;

        if (isBottom) {
            setIsAtBottom(true);
            sectionRef.current = null;
        } else if (isAtBottom && isScrolledUpMoreThan20px) {
            setIsAtBottom(false);
            sectionRef.current = mainSectionRef.current; // Use the ref to the mainSection
        } else {
            sectionRef.current = mainSectionRef.current;
        }
    };

    useGSAP(
        () => {
            gsap.to(arrowRef.current, { y: "-0.5rem", duration: 1.5, repeat: -1, yoyo: true, ease: "sine.inOut" });
        },
    );

    const containersRef = useRef([]);

    useLayoutEffect(() => {
        ref.current.forEach((image, index) => {
            gsap.fromTo(
                image,
                {
                    // backgroundPositionY: `-${window.innerHeight / 2}px`,
                    backgroundPositionY: `-${300}px`,
                },
                {
                    // backgroundPositionY: `${window.innerHeight / 2}px`,
                    backgroundPositionY: `${0}px`,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: image,
                        start: "top center",
                        end: "bottom center",
                        scrub: true,
                        onEnter: () => setCurrentSection(index),
                        onEnterBack: () => setCurrentSection(index),
                    },
                }
            );
        });

        gsap.fromTo(
            textRef.current,
            {
                x: `-${window.innerWidth / 1.2}px`,
            },
            {
                x: 0,
                scrollTrigger: {
                    trigger: textRef.current,
                    scrub: 0.5
                }
            }
        )

        // gsap.fromTo(
        //     textMinorRef.current,
        //     {
        //         opacity: 0.6,
        //         scale: 0.8,
        //         yPercent: 10,
        //         x: `-${window.innerWidth / 2}px`,
        //     },
        //     {
        //         opacity: 1,
        //         scale: 1,
        //         yPercent: 0,
        //         x: `${window.innerWidth / 2}px`,
        //         scrollTrigger: {
        //             trigger: textMinorRef.current,
        //             scrub: 1
        //         }
        //     }
        // )

        window.addEventListener('scroll', handleScroll);

        // Cleanup on unmount
        return () => {
            ref.current.forEach(el => {
                ScrollTrigger.getById(el.id) && ScrollTrigger.getById(el.id).kill();
            });
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAtBottom]);

    useEffect(() => {
        const animations = containersRef.current.map((container) => {
            return gsap.fromTo(
                container,
                { autoAlpha: 0, y: 0 }, // Start with opacity 0 and translateY 50px
                {
                    autoAlpha: 1, // Animate to opacity 1
                    y: 0, // Animate to translateY 0
                    duration: 1.2,
                    scrollTrigger: {
                        trigger: container,
                        start: "top 80%", // Start the animation when the container is 80% from the top of the viewport
                        toggleActions: "play none none none", // Animation will reverse when scrolling back up
                    },
                }
            );
        });

        // Cleanup function to kill ScrollTriggers on unmount
        return () => {
            animations.forEach(animation => animation.scrollTrigger && animation.scrollTrigger.kill());
        };
    }, []);

    const content = {
        hero: [{
            subtitle: 'I\'m a designer and programmer dedicated to creating user-friendly experiences and continuously learning the new skills. <br /> 🧑‍🎨🧑‍🎨👨‍💻👨‍💻',
            background: '#fff'
        }],
        main: [
        {
            title: 'Plugin Developer - Unity',
            subtitle: 'Oink Animation is a powerful and versatile tool designed to streamline the creation, management, and triggering of 2D GUI animations within the Unity engine. Whether you\'re a seasoned developer or just starting out, Oink Animation offers a fast, low-code, and highly customizable workflow that simplifies the implementation of dynamic and engaging user interfaces.',
            herf: "https://assetstore.unity.com/packages/2d/gui/2d-gui-animation-framework-293489#description",
            ready: true,
            background: '#212A31',
            images: plug_1,
        },
        {
            title: 'Plugin Developer - Figma',
            subtitle: 'nlock new levels of design flexibility with our powerful Figma plugin, designed to grow with your needs. Whether you\'re adjusting layout margins and padding, applying custom text styles, or generating placeholder content, this plugin streamlines your workflow. Perfect for designers managing complex layouts, styling text efficiently, and adding placeholders for images, text, emails, or phone numbers—all in just a few clicks. Plus, with regular updates and new features on the way, this tool will continue to evolve, giving you even more power to speed up your design process.',
            herf: "https://www.figma.com/community/plugin/1412622701695231289/oink-styleup-the-all-in-one-design-tool",
            ready: true,
            background: '#212A31',
            images: plug_2,
        },
        {
            title: 'Individual Developer - IOS',
            subtitle: 'In this project, I designed and developed a game application as an individual developer, targeting young people who enjoy socializing and gaming at parties. They don\'t need to carry around cards or boards; all they need is an iPhone or iPad with this app installed to play games.',
            herf: "https://pdfjm.cn/api/pdf/pdf?id=NDU1Nzg=",
            ready: true,
            background: '#212A31',
            images: lady_1,
        },
        {
            title: 'Mixed Reality Developer - VisionOS',
            subtitle: 'It is a collaborative project between BMW and the Savannah College of Art and Design. We designed and developed a VisionOS app that allows users to experience BMW products in an immersive space and promote the BMW brand. The outcome is a runnable prototype that can be interactive in Vision Pro.',
            herf: "https://www.bilibili.com/video/BV12cH8egErL/",
            ready: true,
            background: '#212A31',
            images: vs_1,
        },
        {
            title: 'Generative Art Developer',
            subtitle: 'This is a collaborative project where we designed and developed an installation art piece exploring the intersection of technology and art. My role on the team is to lead the development of the project.',
            herf: "https://www.bilibili.com/video/BV1B32rYzEZH/?share_source=copy_web&vd_source=13409b2d62b58b65ba5b8ec926fd418d",
            ready: true,
            background: '#212A31',
            images: post_1,
        }],
    };

    const scrollToFirstSection = () => {
        if (firstSectionRef.current) {
            firstSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToSection = (index) => {
        if (ref.current[index]) {
            ref.current[index].scrollIntoView({ behavior: 'smooth' });
            setCurrentSection(index);
        }
    };

    const handleUpClick = () => {
        if (currentSection > 0) {
            scrollToSection(currentSection - 1);
        }
    };

    const handleDownClick = () => {
        if (currentSection < content.main.length - 1) {
            scrollToSection(currentSection + 1);
        }
    };

    return (
        <div className='mainSection'>
            <div className='hero'>
                <h3 className='top'>👋 Hi, I'm Haoming</h3>
                <div className="mid">
                    <div className="mid__container">
                        <p className="mid__container__text">
                            I do
                        </p>

                        <ul className="mid__container__list">
                            <li className="mid__container__list__item"><span>[</span> UI/UX Design <span>]</span></li>
                            <li className="mid__container__list__item"><span>[</span> Front-end Program <span>]</span></li>
                            <li className="mid__container__list__item"><span>[</span> Generative Art <span>]</span></li>
                            <li className="mid__container__list__item"><span>[</span> Technical Design <span>]</span></li>
                        </ul>
                    </div>
                </div>
                <h4 className='bot' dangerouslySetInnerHTML={{ __html: content.hero[0].subtitle }} ></h4>
                <a className='arrow' onClick={scrollToFirstSection}>
                    <ArrowDownOutlined style={{ fontSize: '2rem' }} ref={arrowRef} />
                    <h5>My<br />works</h5>
                </a>

                <div className='show'>
                    <h2 ref={textRef} className='show__top'>
                        According to ChatGPT:
                    </h2>

                    <div ref={textMinorRef} class="show__timeline">
                        <h2 className='Title__Section'>
                            My Journey
                        </h2>
                        <div class="timeline">
                            <div class="timeline-item">
                                <div class="timeline-photo">
                                    {/* <img src="https://i.ibb.co/b5rSgP1/ua.jpg" /> */}
                                    <img src={ua_img} />
                                </div>
                                <div class="timeline-headline">
                                    <p class="timeline-subtitle">2020</p>
                                    <h2 class="timeline-title">December</h2>
                                    <p class="timeline-excerpt">I earned a BFA in Visual Design with dual minors in Mathematics and Information Science at The University of Arizona.</p>
                                </div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-photo">
                                    {/* <img src="https://i.ibb.co/R7pMppn/scad.jpg" /> */}
                                    <img src={scad_img} />
                                </div>
                                <div class="timeline-headline">
                                    <p class="timeline-subtitle">2024</p>
                                    <h2 class="timeline-title">June</h2>
                                    <p class="timeline-excerpt">I earned an MFA in Interactive Design and Game Development at Savannah College of Art and Design.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className='show__next Title__Section'>
                        Recent Works&#128071;
                    </h2>
                </div>
            </div>

            <div className='sections' ref={mainSectionRef}>
                {content.main.map((item, index) => (
                    <section
                        ref={(el) => {
                            // addtoRefs(el);
                            containersRef.current[index] = el;
                            if (index === 0) {
                                firstSectionRef.current = el; // Assign first section to firstSectionRef
                            }
                        }}
                        key={index}
                        className='other'
                    >
                        <span className='top'></span>
                        <div className='showcase'>
                            <div className='text'>
                                <div className='title'>
                                    <div className="controls">
                                        <a
                                            className={`up ${currentSection === 0 ? 'disabled' : ''}`}
                                            onClick={handleUpClick}
                                        >&uarr;</a>
                                        <a
                                            className={`down ${currentSection === content.main.length - 1 ? 'disabled' : ''}`}
                                            onClick={handleDownClick}
                                        >&darr;</a>
                                    </div>
                                    <span className="note">
                                        <span className="activeSlide">{currentSection + 1}</span> /
                                        <span className="slideTotal"> {content.main.length}</span>
                                    </span>
                                    <h2>{item.title}</h2>
                                    <p>{item.subtitle}</p>
                                </div>
                                <a href={item.herf} className='no-style-link' target="_blank" rel="noopener noreferrer">
                                    <div className={`explore ${item.ready ? '' : 'notready'}`}>
                                        <span>explore</span>
                                        <RightOutlined style={{ fontSize: '1.0rem' }} />
                                        <div className='deco'></div>
                                    </div>
                                </a>
                            </div>

                            <div
                                ref={(el) => { addtoRefs(el) }}
                                className='image'
                                style={{
                                    backgroundImage: `url(${item.images})`,
                                    backgroundSize: 'cover',   // Ensures the image covers the entire container
                                    backgroundPosition: 'center', // Centers the background image
                                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                                }}
                            >
                            </div>
                        </div>
                        <span className='bot'></span>
                    </section>
                ))}
            </div>
        </div>
    );
};

export default HomePage;