/*
  为子节点提供进度控制的组件
  row 1: Contains Logo + contact section + social media sections
  row 2: Contains Copy right + web navigation
*/
  // Module:
import React, { Children } from 'react';
import { PropTypes } from "prop-types";

const Progress = ({myId, progress, style, data, children}) => {
    const appliedStyle = style ?? {}; // If style is undefined, use an empty object
    const childrenWithProps = Children.map(children, child => {
        // Cloning each child with the new data
        return React.cloneElement(child, { data: data, myId: myId, progress: progress});
    });
    return (
        <div className={'progress_'+myId} style={appliedStyle}>
            {childrenWithProps}
        </div>
    );
};

Progress.propTypes = {
    progress: PropTypes.number.isRequired,
    myId: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    style: PropTypes.object,
};

export default Progress;