import React from 'react';
// import PropTypes from 'prop-types';
import '../../styles/CustomButton.scss';
import { CloseOutlined, CheckOutlined, RightOutlined, PlayCircleOutlined } from '@ant-design/icons';

const urbanSprite = "/assets/portfolio/urban-sprite.png";
// Play Button
const PlayButton = ({ link }) => {
  return (
    <a className="oink-play-container no-style-link" href={link} target="_blank" rel="noopener noreferrer">
      <div className='hover-content'>
        <PlayCircleOutlined style={{ fontSize: '18px' }} />
      </div>

      <div className='button hover-content' style={maskImageStyle}>
        <PlayCircleOutlined style={{ fontSize: '18px' }} />
      </div>
    </a>
  )
}
// More Button
const maskImageStyle = {
  WebkitMaskImage: `url(${urbanSprite})`,
  maskImage: `url(${urbanSprite})`,
};

const MoreButton = ({ link }) => {
  return (
    <a className="oink-more-container no-style-link" href={link} target="_blank" rel="noopener noreferrer">
      <div className='hover-content'>
        <span className="mas">More</span>
        <RightOutlined style={{ fontSize: '20px' }} />
      </div>

      <div className='button hover-content' style={maskImageStyle}>
        <span className="mas">More</span>
        <RightOutlined style={{ fontSize: '15px' }} />
      </div>
    </a>
  );
};

// ButtonMenuList: help popup
const ButtonMenuList = ({ text = 'text', onClick = () => { } }) => {
  return (
    <div className="my-button buttonMenuList" onClick={onClick}>
      {text}
    </div>
  );
};


// ButtonMenu: 
const ButtonMenu = ({ text = 'text', onClick = () => { } }) => {
  return (
    <div className="my-button buttonMenu" onClick={onClick}>
      {text}
    </div>
  );
};

// ButtonMain: autofill hero
const ButtonMain = ({ style, children, state = 1, onClick = () => { } }) => {
  return (
    <div style={style} className={`my-button buttonMain ${state === 0 ? 'disable' : ''}`} onClick={onClick}>
      {/* <a> */}
        {children}
      {/* </a> */}
    </div>
  );
};


// ButtonSubmit: Contact form
const ButtonSubmit = React.memo(({ text = 'text', checked = false, onClick = () => { } }) => {
  return (
    <div className={`my-button buttonSubmit ${checked ? 'checked' : ''}`} onClick={onClick}>
      <div className='button-text'>
        <span>{text}</span>
        <i className='icon-check'><CheckOutlined /></i>
      </div>
      <svg className='circle'>
        <circle cx='31' cy='31' r='30' />
      </svg>
    </div>
  );
});

// ButtonClose
const ButtonClose = ({ onClick }) => {
  return (
    <div className="my-button buttonClose" onClick={onClick}>
      <CloseOutlined />
    </div>
  );
}

// ButtonWindow: cookie popup
const ButtonWindow = ({ text = 'text', isMain = true, onClick = () => { } }) => {
  const className = `my-button buttonWindow ${isMain ? 'main' : 'secondary'}`;
  return (
    <div className={className} onClick={onClick}>
      {text}
    </div>
  );
};

export { ButtonMenu, ButtonMain, ButtonSubmit, ButtonMenuList, ButtonClose, ButtonWindow, MoreButton, PlayButton };