/*
  The code below will generate the Profile page
  Contains Logo + Username + Inquiry remain
*/
// Module:
import React from "react";
// components
// import { ButtonMain } from '../../../components/buttons/Button_Common';
import LineDecoration from '../../../components/others/LineDecorations';
// assets
const heroImg = '/assets/portfolio/frontend/letsplay/letsplay_hero.png';
const logo_avatar = '/assets/portfolio/frontend/letsplay/AppIcon.png';
// static

const HeroSection = () => {
  return (
    <>
      <div className="heroSectionAnimated">
        <img className="logoImg" src={logo_avatar} alt="Logo" />
        <div className="titles">
          <h1>LetsPlay</h1>
          <p>A digital app that brings friends together for multiplayer fun without the need for physical cards or boards.</p>
        </div>
        <div className="descrip">
          <div className="content">
            <h4>My role</h4>
            <LineDecoration offsetX={30} width='20px' backgroundColor="#282f40"/>
            <span>Individual Developer</span>
          </div>
          <div className="content">
            <h4>Scope</h4>
            <LineDecoration offsetX={51} width='20px' backgroundColor="#282f40"/>
            <span>4 month personal project</span>
          </div>
        </div>
        <img className="supportImg" src={heroImg} alt="Logo" />
      </div>
    </>
  );
}

export default HeroSection;