/*
  The code below will generate the footer
  row 1: Contains Logo + contact section + social media sections
  row 2: Contains Copy right + web navigation
*/
// Module:
import React from 'react';
// Styles:
import '../../styles/footer.scss';
// Assets:
import { LinkedinOutlined, InstagramOutlined } from '@ant-design/icons';


const navigation = {
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/haoming101000101?igsh=MTNwYTNpZzh4MGxheg%3D%3D&utm_source=qr',
      icon: (props) => (
        <InstagramOutlined
          style={{
            fontSize: '24px',
          }}
        />
      ),
    },
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/in/haoming-lang-026207201/',
      icon: (props) => (
        <LinkedinOutlined
          style={{
            fontSize: '24px',
          }}
        />
      ),
    },
  ],
}

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-bottom">
          <div className="social-links">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="social-link no-style-link" target="_blank" title=" " >
                <item.icon className="social-icon" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="footer-copy">
            Website design and code by © 2024 Haoming Lang
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;