import React, { useState, useRef, useEffect } from 'react';
import { Spin, Progress } from 'antd';
import './ImageUpload.scss';
import { ButtonMain } from '../../components/buttons/Button_Common';

const base_url = "http://9441l2477q.zicp.fun";
// const base_url = "http://kh944vq1247.vicp.fun";
const img2img_url = "/sdapi/v1/img2img";
const interrogate_url = "/sdapi/v1/interrogate";
const progress_url = "/sdapi/v1/progress";
// const interrogate_url = "/sdapi/v1/options";

const twoColors = {
    '0%': '#748D92',
    '100%': '#FBA92C',
  };
  
const SDWebui = () => {
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [loading, setLoading] = useState(false);
    const [processedImageURL, setProcessedImageURL] = useState(null);
    const fileInputRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const progressIntervalRef = useRef(null);

    useEffect(() => {
        // let interval;
        if (loading) {
            progressIntervalRef.current = setInterval(fetchProgress, 1000);
        }
        return () => clearInterval(progressIntervalRef.current);
    }, [loading]);

    const handleUploadImage = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImageURL(URL.createObjectURL(file));
    };

    const handleClear = () => {
        window.location.reload();
    };

    const fetchProgress = async () => {
        try {
            const response = await fetch(`${base_url}${progress_url}`);
            const data = await response.json();
            setProgress((data.progress * 100).toFixed(2));;
        } catch (error) {
            console.error("Error fetching progress:", error);
        }
    };

    const handleSend = async () => {
        if (!image) return;
        setLoading(true);
        setProgress(0);
        try {
            console.log("start generate!!");
            const resizedImage = await resizeImage(image, 500);
            const base64Image = await blobToBase64(resizedImage);

            // 去除base64数据头部信息，例如 "data:image/png;base64,"
            const base64Data = base64Image.split(',')[1];

            const requestBody_interrogate = {
                "image": base64Data,
                "model": "clip"
            }

            const interrogate = await fetch(`${base_url}${interrogate_url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody_interrogate)
            });

            const interrogate_data = await interrogate.json();
            const interrogate_caption = interrogate_data.caption;

            console.log(interrogate_caption);

            const requestBody_img2img = {
                // main setting
                "override_settings": {
                    "sd_model_checkpoint": "helloflatart_v16gvae.safetensors [9c5c787100]",
                    // "sd_vae": "pastel-waifu-diffusion.vae.pt"
                },
                //
                // "override_settings_restore_afterwards": true,
                "alwayson_scripts": {
                    // controlnet
                    "controlnet": {
                        "args": [
                            {
                                "control_mode": "Balanced",
                                "enabled": true,
                                "guidance_end": 1,
                                "guidance_start": 0.0,
                                "input_image": base64Data,
                                "lowvram": false,
                                "model": "control_v11p_sd15_canny_fp16 [b18e0966]",
                                "module": "canny",
                                "pixel_perfect": true,
                                "processor_res": 512,
                                "resize_mode": "Crop and Resize",
                                "threshold_a": 100,
                                "threshold_b": 200,
                                "weight": 1
                            },
                            {
                                "control_mode": "Balanced",
                                "enabled": true,
                                "guidance_end": 1.0,
                                "guidance_start": 0.0,
                                "input_image": base64Data,
                                "lowvram": false,
                                "model": "control_v11p_sd15_openpose_fp16 [73c2b67d]",
                                "module": "openpose_full",
                                "pixel_perfect": false,
                                "processor_res": 512,
                                "resize_mode": "Crop and Resize",
                                "threshold_a": 0.5,
                                "threshold_b": 0.5,
                                "weight": 1
                            },
                            {
                                "control_mode": "Balanced",
                                "enabled": true,
                                "guidance_end": 1.0,
                                "guidance_start": 0.0,
                                "input_image": base64Data,
                                "lowvram": false,
                                "model": "control_v11f1p_sd15_depth_fp16 [4b72d323]",
                                "module": "depth_midas",
                                "pixel_perfect": false,
                                "processor_res": 512,
                                "resize_mode": "Crop and Resize",
                                "threshold_a": 0.5,
                                "threshold_b": 0.5,
                                "weight": 1
                            }
                        ]
                    },
                    //
                    // adetailer
                    "ADetailer": {
                        "args": [true,
                            {
                                "ad_model": "person_yolov8n-seg.pt",
                            },
                            {
                                "ad_model": "face_yolov8n.pt",
                            }
                        ]
                    },
                    //
                },
                "init_images": [base64Data],
                "seed": -1,
                "steps": 20,
                "width": 512,
                "height": 512,
                "cfg_scale": 7,
                "denoising_strength": 0.6,
                "sampler_name": "DPM++ 2M",
                "scheduler": "Automatic",
                "prompt": interrogate_caption,
                "negative_prompt": "low quality,bad proportions",
                // "sampler_index": "Euler",
                // "script_args": [
                // ],
            };

            const response = await fetch(`${base_url}${img2img_url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody_img2img)
            });

            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                if (response.status === 200) {
                    const newImageBase64 = data.images[0];
                    setProcessedImageURL('data:image/png;base64,' + newImageBase64);
                } else if (response.status === 422) {
                    console.error("Validation error:", data);
                    alert("Validation error occurred.");
                } else if (response.status === 500) {
                    console.error("Server error:", data);
                    alert("Server error occurred.");
                } else {
                    console.error("Unexpected error:", response);
                    alert("Unexpected error occurred.");
                }
            } else {
                const text = await response.text();
                console.error("Received HTML response:", text);
                alert("An error occurred during image processing: Unexpected HTML response.");
            }
        } catch (error) {
            console.error("Error during image processing:", error);
            alert(`An error occurred during image processing: ${error.message || error}`);
        } finally {
            setLoading(false);
            clearInterval(progressIntervalRef.current); // Stop fetching progress
            // console.log("@@@@@ finished");
        }
    }

    const handleSave = () => {
        const link = document.createElement('a');
        link.href = processedImageURL;
        link.download = 'processed_image.png';
        link.click();
    };

    const handleRegenerate = async () => {
        handleSend();
    };

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="image-upload">
            <ButtonMain onClick={() => handleFileInputClick()} >
                <span style={{}}>上传照片</span>
            </ButtonMain>
            {/* <button onClick={handleFileInputClick}>上传照片</button> */}
            <input
                type="file"
                accept="image/*"
                onChange={handleUploadImage}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            {imageURL && (
                <div className="image-preview">
                    <img src={imageURL} alt="Preview" />
                    <div className='button-line'>
                        <ButtonMain onClick={() => handleClear()} >
                            <span style={{}}>清除</span>
                        </ButtonMain>
                        <ButtonMain onClick={() => handleSend()} >
                            <span style={{}}>ai生成</span>
                        </ButtonMain>
                        {/* <button onClick={handleClear}>清除</button>
                        <button onClick={handleSend}>ai生成</button> */}
                    </div>
                </div>
            )}
            {loading && (
                <div>
                    <Spin />
                    <div className="loading">
                        加载中...
                        <Progress
                            percent={progress}
                            percentPosition={{
                                align: 'center',
                                type: 'outer',
                            }}
                            size={[300, 30]}
                            strokeColor={twoColors}
                        />
                    </div>
                </div>
            )}
            {processedImageURL && (
                <div className="processed-image">
                    <div>
                        <img src={processedImageURL} alt="Processed" />
                    </div>
                    <div className='button-line'>
                    <ButtonMain onClick={() => handleSave()} >
                            <span style={{}}>保存</span>
                        </ButtonMain>
                        <ButtonMain onClick={() => handleRegenerate()} >
                            <span style={{}}>重新生成</span>
                        </ButtonMain>
                        {/* <button onClick={handleSave}>保存</button>
                        <button onClick={handleRegenerate}>重新生成</button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

async function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

function dataURLToBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const buffer = new ArrayBuffer(byteString.length);
    const data = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
        data[i] = byteString.charCodeAt(i);
    }
    return new Blob([buffer], { type: mimeString });
}

function resizeImage(file, maxWidth) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const reader = new FileReader();

        reader.onload = (e) => {
            img.src = e.target.result;
        };

        img.onload = () => {
            let width = img.width;
            let height = img.height;

            if (width <= maxWidth) {
                resolve(file);
                return;
            }

            height *= maxWidth / width;
            width = maxWidth;

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(resolve, file.type);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export default SDWebui;