import React from 'react';
import style from './Error.module.scss';

const Error = () => {
    return (
        <div className={style.myComponent}>
            <h1 className={style.tite}>404</h1>
            <div className={style.textSection}>
                <span className={style.text}>Page Not Found ;(</span>
                <span className={style.text}>Haoming is working on it!</span>
            </div>
        </div>
    );
};

export default Error;